import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import loadable from '@loadable/component';
import { Box, Stack, Text } from 'grommet';

import ImgixImage from '../ImgixImage';
import { track } from '../../lib/analytics';
import { PRODUCT_LIST_PRODUCT_HOVERED } from '../../lib/analytics/segmentActions';
import useHover from '../useHover';
import useMobile from '../useMobile';
import { findSampleVariant } from '../../lib/product';
import formatCurrency from '../../lib/formatCurrency';
import SmartLink from '../SmartLink';
import ButtonAddToCart from '../ButtonAddToCart';
import useAdjustedHeadingSize from '../useAdjustedHeadingSize';
import styled from 'styled-components';

const InputSelectWithDescription = loadable(() =>
  import('../Inputs/InputSelectWithDescription')
);
const ProductCardInfo = loadable(() => import('../Product/ProductCardInfo'));
const ProductBadges = loadable(() => import('../Product/ProductBadges'));

const Container = styled(Box)`
  padding-right: ${(p) =>
    p.templateSize === 'medium' ? p.theme.global.edgeSize.large : 0};
  padding-left: ${(p) =>
    p.templateSize === 'medium' ? p.theme.global.edgeSize.large : 0};

  @media only screen ${(p) =>
      `and (min-width: ${p.theme.global.breakpoints.small.value + 1}px)`} {
    padding-left: ${(p) => p.theme.global.edgeSize.xsmall};
    padding-right: ${(p) => p.theme.global.edgeSize.xsmall};
  }
`;

const PaintColorCard = (props) => {
  const {
    title,
    collectionImageUrl,
    productField,
    productOptions,
    productType,
    overlay,
    tags,
    hexValue,
    heroImageUrl,
    isDark,
    variants,
    description,
    shortDescription,
    onAddToCart,
    detailsPath,
    collectionContext,
    srcSetSizes = '(max-width: 768px) 80vw, 15vw',
    imageContainerProps = {},
    containerProps = {},
    badgeContainerProps = {},
    showShortDescription,
    size = 'medium',
    hideSampleButton = false,
    hideTags = false,
    hideTitle,
    templateSize,
    ...rest
  } = props;
  const isMobile = useMobile();
  const [hoverRef, isHovered] = useHover(() => {
    track(
      PRODUCT_LIST_PRODUCT_HOVERED,
      {
        product: title,
      },
      {
        integrations: {
          Klaviyo: false,
        },
      }
    );
  });

  const sampleVariant = findSampleVariant(variants);

  const titleStyle = {
    ...useAdjustedHeadingSize(size === 'small' ? 6 : 5),
    letterSpacing: '1px',
  };

  return (
    <Box ref={hoverRef} {...rest}>
      <Stack fill anchor="top-right">
        <Stack fill>
          <Box flex={true} fill>
            <Container
              flex={true}
              pad={{
                vertical: 'small',
                bottom: 'medium',
              }}
              fill
              gap="small"
              align="center"
              templateSize={templateSize}
              {...containerProps}
            >
              <Box
                className="image-container"
                align="center"
                justify="center"
                fill
                pad={{ horizontal: 'medium' }}
                {...imageContainerProps}
              >
                <SmartLink
                  to={detailsPath}
                  plain
                  fill
                  title={title}
                  as={Box}
                  compAs={'a'}
                  focusIndicator={false}
                >
                  <ImgixImage
                    src={collectionImageUrl}
                    srcSetOptions={{ q: 75, auto: ['compress', 'format'] }}
                    sizes={srcSetSizes}
                    alt={title}
                    fit="contain"
                    width="100%"
                    lazy={true}
                  />
                </SmartLink>
              </Box>
              {productField ? (
                <Box width="auto" alignSelf="center">
                  <Field
                    component={InputSelectWithDescription}
                    name={productField}
                    size="small"
                    options={productOptions}
                    alignSelf="center"
                    className="lessPad"
                    plain
                  />
                </Box>
              ) : productType ? (
                <Box margin={{ top: 'small' }}>
                  <Box margin={{ vertical: 'small' }}>
                    <Text align="center" textAlign="center">
                      {productType}
                    </Text>
                  </Box>
                </Box>
              ) : null}
              {!hideTitle && (
                <Box
                  className="info-container"
                  flex={{ grow: 1 }}
                  justify="between"
                  height={{ min: '2em' }}
                >
                  {!hideTitle && (
                    <Box className="text-container">
                      <Text
                        textAlign="center"
                        style={titleStyle}
                        margin="none"
                        weight={500}
                      >
                        <SmartLink to={detailsPath} plain>
                          {title}
                        </SmartLink>
                      </Text>
                      {showShortDescription && (
                        <Text textAlign="center" size="medium">
                          {shortDescription}
                        </Text>
                      )}
                    </Box>
                  )}
                  {isMobile &&
                    !productField &&
                    sampleVariant &&
                    !hideSampleButton && (
                      <Box align="center" className="actions-container">
                        <ButtonAddToCart
                          textAlign="center"
                          margin={{ top: 'small' }}
                          style={{
                            padding: '0.3rem 1.4rem',
                            width: 'fit-content',
                            fontWeight: 400,
                          }}
                          secondary
                          label={
                            sampleVariant
                              ? `${formatCurrency(sampleVariant.price)} Sample`
                              : ''
                          }
                          useIcon={false}
                          variantId={sampleVariant.shopifyId}
                        />
                      </Box>
                    )}
                </Box>
              )}
            </Container>
          </Box>
          {overlay && isHovered && !isMobile && (
            <Box flex={true} fill background={{ color: hexValue }}>
              <ProductCardInfo
                fill
                title={title}
                imageUrl={heroImageUrl}
                sampleVariant={sampleVariant}
                backgroundColor={hexValue}
                isDark={isDark}
                variants={variants}
                description={shortDescription || description}
                collectionContext={collectionContext}
                detailsPath={detailsPath}
                onAddToCart={onAddToCart}
                srcSetSizes={srcSetSizes}
              />
            </Box>
          )}
        </Stack>
        {(overlay && isHovered) || hideTags ? null : (
          <Box {...badgeContainerProps}>
            <ProductBadges tags={tags} />
          </Box>
        )}
      </Stack>
    </Box>
  );
};

PaintColorCard.propTypes = {
  title: PropTypes.string,
  overlay: PropTypes.bool,
  onAddToCart: PropTypes.func,
  hexValue: PropTypes.string,
  cta: PropTypes.string,
  collectionImageUrl: PropTypes.string,
  heroImageUrl: PropTypes.string,
  productField: PropTypes.string,
  productOptions: PropTypes.array,
  productType: PropTypes.string,
  isDark: PropTypes.bool,
  tags: PropTypes.array,
  variants: PropTypes.array,
  description: PropTypes.string,
  shortDescription: PropTypes.string,
  handle: PropTypes.string,
  detailsPath: PropTypes.string,
  collectionContext: PropTypes.object,
  srcSetSizes: PropTypes.string,
  imageContainerProps: PropTypes.object,
  containerProps: PropTypes.object,
  badgeContainerProps: PropTypes.object,
  showShortDescription: PropTypes.bool,
  hideSampleButton: PropTypes.bool,
  hideTags: PropTypes.bool,
  hideTitle: PropTypes.bool,
};

export default memo(PaintColorCard);
