import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import makeSelectPaintCollectionByProductId from '../../state/product/makeSelectPaintCollectionByProductId';
import { head, get, flatten, map } from '../../lib/nodash';
import {
  findMetafieldValue,
  findImageMetafield,
  getMetafieldTags,
  hasDarkTag,
  findSampleVariant,
} from '../../lib/product';

const usePaintCollectionData = (collection, product) => {
  const selectPaintCollectionByProductId = useMemo(
    makeSelectPaintCollectionByProductId,
    []
  );
  const paintCollection = useSelector(
    (state) =>
      collection ||
      selectPaintCollectionByProductId(state, get('shopifyId', product))
  );

  const products = get('products', paintCollection) || [];

  const metafields = get('metafields', paintCollection);
  const hexValue = findMetafieldValue('hex_value', metafields);
  const shortDescription = findMetafieldValue('short_description', metafields);
  const paintDetailImage = useMemo(
    () => head(findImageMetafield('hero_image', metafields)),
    [metafields]
  );

  const collectionImage = useMemo(() => {
    const fields = findImageMetafield('collection_image', metafields);
    return Array.isArray(fields) ? head(fields) : fields;
  }, [metafields]);

  const heroImage = useMemo(() => {
    const fields = findImageMetafield('hero_image', metafields);
    return Array.isArray(fields) ? head(fields) : fields;
  }, [metafields]);

  const tags = getMetafieldTags(metafields);
  const isDark = useMemo(() => hasDarkTag(tags), [metafields]);
  const variants =
    get('variants', product) ||
    flatten(map((x) => get('variants', x), products));

  const sampleVariant = useMemo(() => findSampleVariant(variants), [variants]);

  return {
    hexValue,
    shortDescription,
    description:
      get('description', paintCollection) || get('description', product),
    variants,
    paintDetailImage,
    collectionImage,
    heroImage,
    tags,
    isDark,
    sampleVariant,
    products,
    title: get('title', paintCollection) || get('title', product),
    handle: get('handle', paintCollection) || get('handle', product),
  };
};

export default usePaintCollectionData;
