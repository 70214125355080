import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { get, find } from '../../lib/nodash';
import { Box, Grid, ResponsiveContext } from 'grommet';
import loadable from '@loadable/component';

import PaintCollectionCard from '../Paint/PaintCollectionCard';
import useMobile from '../useMobile';
import useRichText from '../useRichText';
import SmartLink from '../SmartLink';
import { getProductPath, findMetafieldValue } from '../../lib/product';
import { SIMILAR_PRODUCT_CLICKED } from '../../lib/analytics/segmentActions';
import makeSelectRelatedProducts from '../../state/product/makeSelectRelatedProducts';

const RelatedProducts = loadable(() => import('../Product/RelatedProducts'));
const RelatedCardComponent = loadable(() =>
  import('../Product/RelatedCardComponent')
);

const DynamicRelatedProducts = ({
  relatedProducts = [],
  relatedCollections = [],
  paintColor,
  product,
  entityType,
  title = 'Optional Extras',
  body,
}) => {
  const isMobile = useMobile();
  const size = useContext(ResponsiveContext);
  const selectRelatedProducts = useMemo(makeSelectRelatedProducts, []);
  const richTextBody = useRichText(body);
  const relatedHandles = (
    findMetafieldValue('products', product?.metafields || [], 'pdp_related') ||
    ''
  ).split('|');
  const productRelatedProducts = useSelector((state) =>
    selectRelatedProducts(state, relatedHandles)
  );

  const related =
    entityType === 'collection'
      ? relatedCollections
      : relatedProducts || productRelatedProducts;

  const colCount =
    size === 'medium' || size === 'medsmall' ? 2 : related.length;

  const Component = isMobile ? Box : Grid;

  const componentProps = isMobile
    ? { direction: 'row', wrap: false }
    : { columns: { size: 'auto', count: colCount } };

  return (
    <>
      {entityType === 'product' ? (
        <RelatedProducts
          relatedProducts={related}
          listItemComponent={RelatedCardComponent}
          metaContainerProps={{ align: 'start' }}
          listContainerProps={{ background: { color: 'lightGray' } }}
          border={{ side: 'horizontal', size: 'small' }}
          title={title}
          altLayout={true}
          description={richTextBody}
        />
      ) : (
        <Box fill justify="center">
          <Component
            {...componentProps}
            gap={paintColor ? 'none' : 'medium'}
            pad="medium"
            fill={isMobile}
            justify={isMobile ? 'start' : 'center'}
            className="inner-scroller"
            overflow="auto"
            style={{
              gridTemplateRows: '1fr',
            }}
          >
            {related.map((x) => {
              const relatedProduct = paintColor
                ? find({ productType: product.productType }, x.products)
                : x;
              return (
                <Box
                  flex={false}
                  fill
                  key={
                    relatedProduct.id ||
                    relatedProduct.shopifyId ||
                    relatedProduct.slug ||
                    relatedProduct.uuid ||
                    relatedProduct._uid
                  }
                  justify={isMobile ? 'center' : 'start'}
                >
                  <SmartLink
                    title={get('title', relatedProduct)}
                    to={getProductPath(relatedProduct)}
                    plain
                    track={{
                      event: SIMILAR_PRODUCT_CLICKED,
                      properties: { product: relatedProduct.title },
                    }}
                  >
                    <PaintCollectionCard
                      product={relatedProduct}
                      collection={x}
                      size="small"
                      showShortDescription={true}
                      imageContainerProps={{ width: { max: '250px' } }}
                    />
                  </SmartLink>
                </Box>
              );
            })}
          </Component>
        </Box>
      )}
    </>
  );
};

DynamicRelatedProducts.propTypes = {
  entityType: PropTypes.oneOf(['product', 'collection']),
  paintColor: PropTypes.object,
  relatedProducts: PropTypes.array,
  relatedCollections: PropTypes.array,
  product: PropTypes.object,
  title: PropTypes.string,
  body: PropTypes.object,
};

export default DynamicRelatedProducts;
