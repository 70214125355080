import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';

import { map, get } from '../../lib/nodash';
import { getProductPath } from '../../lib/product';
import PaintColorCard from './PaintColorCard';
import usePaintCollectionData from './usePaintCollectionData';

const PaintCollectionCard = ({
  collection,
  product,
  overlay = true,
  productField,
  productType,
  onAddToCart,
  collectionContext,
  srcSetSizes,
  size = 'medium',
  hideSampleButton,
  hideTags,
  templateSize,
  ...rest
}) => {
  const paintCollection = usePaintCollectionData(collection, product);
  const productOptions = useMemo(
    () =>
      map(
        (x) => ({ label: x.productType, value: x.shopifyId }),
        paintCollection.products
      ),
    [paintCollection.products]
  );

  return (
    <PaintColorCard
      collectionImageUrl={
        get('collectionImage.original_src', paintCollection) ||
        get('collectionImage.originalSrc', paintCollection) ||
        get('collectionImage', paintCollection)
      }
      heroImageUrl={
        get('heroImage.original_src', paintCollection) ||
        get('heroImage.originalSrc', paintCollection) ||
        get('heroImage', paintCollection)
      }
      productOptions={productOptions}
      onAddToCart={onAddToCart}
      productType={productType}
      productField={productField}
      detailsPath={product ? getProductPath(product) : undefined}
      collectionContext={collectionContext}
      srcSetSizes={srcSetSizes}
      overlay={overlay}
      size={size}
      {...rest}
      {...paintCollection}
      hideSampleButton={hideSampleButton}
      hideTags={hideTags}
      templateSize={templateSize}
    />
  );
};

PaintCollectionCard.propTypes = {
  collection: PropTypes.object,
  collectionContext: PropTypes.object,
  product: PropTypes.object,
  routeType: PropTypes.string,
  description: PropTypes.string,
  overlay: PropTypes.bool,
  productField: PropTypes.string,
  headingLevel: PropTypes.number,
  productType: PropTypes.string,
  onAddToCart: PropTypes.func,
  srcSetSizes: PropTypes.string,
  hideSampleButton: PropTypes.bool,
  hideTags: PropTypes.bool,
  size: PropTypes.string,
  templateSize: PropTypes.string,
};

export default memo(PaintCollectionCard);
